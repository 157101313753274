<template>
  <crud-form
    :tabs-cabecalho="tabCabecalhosValendo"
    :titulo-pagina-tab="tituloPaginaTab"
    :is-editting="isEditting"
    :tab-selecionada.sync="tab"
    :mensagem-pergunta-remocao="mensagemPerguntaRemocao"
    @deleteEvent="remover"
    @closeDialogEvent="closeDialog"
    @saveEvent="saveEventHandler"
    @save-continue-event="saveContinueEvent"
  >
    <v-tabs-items v-model="tab">
      <v-tab-item :value="'tab-dados-gerais'">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="objEditar.titulo"
                class="purple-input"
                label="Título"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="objEditar.descricao"
                class="purple-input"
                label="Descrição"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="objEditar.bloco"
                label="Bloco"
                class="purple-input"
                mask="######"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="objEditar.apartamento"
                label="Apartamento"
                class="purple-input"
                mask="######"
              />
            </v-col>
            <v-col cols="6" />
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item :value="'tab-imagens'">
        <v-col
          cols="10"
          offset="1"
        >
          <v-card>
            <v-toolbar color="green">
              <v-toolbar-title class="text-center">
                Imagens
              </v-toolbar-title>
              <v-spacer />
              <v-btn
                icon
                @click="adicionarImagem"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list subheader>
              <template v-for="(imagem,index) in imagens">
                <v-list-item :key="index">
                  <v-list-item-avatar>
                    <img
                      :src="imagem.img"
                      class="cursorPointer"
                      @click="visualizaImagem(imagem)"
                    >
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ imagem.titulo }}</v-list-item-title>
                    <v-list-item-sub-title>{{ imagem.comentario }}</v-list-item-sub-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon
                      color="teal"
                      @click="editarImagem(imagem)"
                    >
                      mdi mdi-tooltip-edit
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index + 1 < imagens.length"
                  :key="`divider-${index}`"
                />
              </template>
            </v-list>
          </v-card>
        </v-col>
        <imagem-upload
          :is-editting="editandoImagem"
          :id-imagem.sync="idImagem"
          :dialog.sync="dialogImagem"
          @imagem-salva="imagemSalva"
          @imagem-removida="imagemRemovida"
        />
      </v-tab-item>
    </v-tabs-items>
    <span
      v-if="tab !== 'tab-dados-gerais'"
      slot="botoes"
    />
  </crud-form>
</template>

<script>

  import ReclamacoesService from '@/services/ReclamacoesService'
  import ImagemService from '@/services/ImagemService'

  export default {

    data: () => ({
      tituloPaginaTab: null,
      tituloPagina: 'Reclamações',
      mensagemPerguntaRemocao: 'Deseja remover esta reclamação?',
      objPadrao: {
        titulo: '',
        descricao: '',
        bloco: 0,
        apartamento: 0,
        imagens: '',
        imagensArr: []
      },
      objEditar: {
        titulo: '',
        descricao: '',
        bloco: 0,
        apartamento: 0,
        imagens: ''
      },
      isEditting: true,
      listaApartamentosArr: [],
      mostraBotaoSalvarContinuar: false,
      tab: null,
      imagens: null,
      idImagem: null,
      dialogImagem: false,
      editandoImagem: false,
      tabCabecalhos: [
        {
          texto: 'Dados Gerais',
          link: '#tab-dados-gerais',
          mostraCabecalho: true
        },
        {
          texto: 'Imagens',
          link: '#tab-imagens',
          mostraCabecalho: false
        }
      ]
    }),

    computed: {
      tabCabecalhosValendo () {
        var tabValendo = this.tabCabecalhos.filter((tab) => {
          return tab.mostraCabecalho
        })
        return tabValendo
      }
    },
    created () {
      this.tituloPaginaTab = this.$t('menu_reclamacoes')
      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      removeApartamento (index) {

      },
      saveContinueEvent () {
        this.inserir(this.objEditar, true)
      },
      saveEventHandler () {
        this.objEditar.listaApartamentos = JSON.stringify(this.listaApartamentosArr)
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (reclamacao) {
        try {
          ReclamacoesService.update(reclamacao)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: 'Reclamação salva com sucesso'
                })
                this.closeDialog()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      closeDialog () {
        this.$router.push('/reclamacoes')
      },
      remover () {
        const registro = this.objEditar
        try {
          ReclamacoesService.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Reclamação removida com sucesso'
              })
              this.closeDialog()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover uma reclamação:\n ->' + error
          })
        }
      },
      inserir (registro, manterNestaTela) {
        try {
          ReclamacoesService.insert(registro).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Reclamação salva com sucesso'
              })
              if (manterNestaTela) {
                this.editar(registro.data.id)
              } else {
                this.closeDialog()
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir uma reclamação:\n ->' + error
          })
        }
      },
      visualizaImagem (imagem) {
        this.$root.$emit('view-image', imagem.img)
      },
      adicionarImagem () {
        this.editandoImagem = false
        this.idImagem = 0
        this.dialogImagem = true
      },
      editarImagem (imagem) {
        this.editandoImagem = true
        this.idImagem = imagem.id
        this.dialogImagem = true
      },
      imagemSalva (idImagem) {
        this.loadingDialog = true
        if (!this.objEditar.imagensArr) {
          this.objEditar.imagensArr = []
        }
        this.objEditar.imagensArr.push(idImagem)
        this.objEditar.imagens = JSON.stringify(this.objEditar.imagensArr)
        if (this.objEditar.id) {
          this.atualizar(this.objEditar, false)
        } else {
          if (this.objEditar.imagensArr && this.objEditar.imagensArr.length > 0) {
            ImagemService.findByIds({
              ids: this.objEditar.imagensArr
            }, {
              rowsPerPage: 1000
            })
              .then(imagensBanco => {
                this.processaRetornoConsultaImagens(imagensBanco)
              })
              .catch(error => {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Ocorreu um erro ao remover uma reclamação:\n ->' + error
                })
              })
              .finally(() => {
                this.loadingDialog = false
              })
          }
        }
      },
      imagemRemovida (idImagem) {
        this.imagens = null
        if (this.objEditar.imagensArr && this.objEditar.imagensArr.indexOf(idImagem) >= 0) {
          this.objEditar.imagensArr.splice(this.objEditar.imagensArr.indexOf(idImagem), 1)
          this.objEditar.imagens = JSON.stringify(this.objEditar.imagensArr)
          if (this.objEditar.id) {
            this.atualizar(this.objEditar, false)
          }
        }
      },
      buscaImagens () {
        if (this.objEditar.imagens && this.objEditar.imagens.length > 0) {
          this.objEditar.imagensArr = JSON.parse(this.objEditar.imagens)
          if (this.objEditar.imagensArr && this.objEditar.imagensArr.length > 0) {
            ImagemService.findByIds({
              ids: this.objEditar.imagensArr
            }, {
              rowsPerPage: 100
            })
              .then(imagens => {
                this.processaRetornoConsultaImagens(imagens)
              })
              .catch(error => {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Ocorreu um erro ao buscar as imagens desta reclamação:\n ->' + error
                })
              })
              .finally(() => {
                this.loadingImagem = false
                this.dialog = true
              })
          } else {
            this.loadingImagem = false
            this.dialog = true
          }
        } else {
          this.loadingImagem = false
          this.dialog = true
        }
      },
      processaRetornoConsultaImagens (imagensBanco) {
        const imagensRetorno = imagensBanco.data
        this.imagens = []
        for (let i = 0; i < imagensRetorno.length; i++) {
          const imagemProcessar = imagensRetorno[i]
          const buffer = Buffer.from(imagemProcessar.img, 'base64')
          var myBase64 = window.btoa(ImagemService.Uint8ToString(buffer))
          imagemProcessar.img = 'data:image/*;base64,' + myBase64
          this.imagens.push(imagemProcessar)
        }
      },
      editar (id) {
        this.imagens = []
        this.loadingImagem = true
        setTimeout(this.buscaImagens, 3000)

        ReclamacoesService.findById({ id })
          .then(registro => {
            if (registro.id) {
              this.objEditar = registro
              this.isEditting = true
            } else if (registro.data.id) {
              this.objEditar = registro.data
              this.isEditting = true
            } else {
              this.isEditting = false
            }
            if (this.isEditting) {
              this.tabCabecalhos.forEach((cadaTab) => {
                cadaTab.mostraCabecalho = true
              })
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
